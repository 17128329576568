.selection-name-container {
  height: 100%;
}

.selection-name-container > .selection-name {
  height: 50%;
  text-align: center;
}

.selection-name-container > .selection-name span {
  font-size: 6vw;
  font-weight: bold;
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.selection-name-container > button.selection-button {
  width: 50%;
  height: 50%;
  font-size: 3vw;
  border: 0;
  cursor: pointer;
}

.selection-name-container > button.selection-select {
  background-color: green;
}

.selection-name-container > button.selection-reject {
  background-color: red;
}
